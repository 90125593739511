.app {
  text-align: center;
}

body {
  background-color: black;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
}

.acutal-holder, .archival-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  width: 100%;
}

.acutal-holder input, .archival-holder input {
  width: 50%;
}

.acutal-holder label, .archival-holder label {
  color: white;
  margin-bottom: 10px;
}

.downloadButton {
  width: 200px !important;
  border: 2px solid #00D5FF;
  background-color: transparent;
  border-radius: 5px;
  padding: 12px 14px;
  margin-top: 20px;
}

.downloadButton .csv-link {
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.loadingSpinner {
  margin: 0 auto;
  margin-top: 30px;
  border: 6px solid #00D5FF;
  border-top: 6px solid black;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

.loadingSpinner-mini {
  margin: 0 auto;
  border: 3px solid #00D5FF;
  border-top: 3px solid black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
